import React from 'react'

import SEO from '~/components/seo'
import Container from '../components/ui/Container'
import { Flex, Box } from 'reflexbox'
import Heading from '../components/ui/Heading'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Container>
      <Flex my={4} justifyContent="center">
        <Box width={[1, 2 / 3, 2 / 3]}>
          <Heading mb={4} level={2} as="h2">
            Page not found
          </Heading>
        </Box>
      </Flex>
    </Container>
  </>
)

export default NotFoundPage
